import { forwardRef } from 'react';
import { Link } from '@mui/material';
// @mui
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  disabledLink?: boolean;
}

const Logo = forwardRef<any, Props>(({ disabledLink = false, sx }, ref) => {
  // OR
  // const logo = '/logo/logo_single.svg';

  const logo = (
    <Box ref={ref} sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}>
      <svg width="100%" height="100%" viewBox="0 0 46 45" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22.5117 21L34 41L16 33.1646L22.5117 21Z" fill="url(#paint0_linear_100_1029)" />
        <rect x="14.4998" y="6.35559" width="13.4252" height="41.7121" rx="6.71259" transform="rotate(-26.5 14.4998 6.35559)"
          fill="url(#paint1_linear_100_1029)" />
        <rect x="20.6568" y="0.0126343" width="12.9582" height="43.265" rx="6.47912"
          transform="rotate(28.5 20.6568 0.0126343)" fill="url(#paint2_linear_100_1029)" />
        <defs>
          <linearGradient id="paint0_linear_100_1029" x1="24.7021" y1="21.2534" x2="20.9483" y2="33.1327"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#C795EA" />
            <stop offset="0.494792" stopColor="#CC89E6" />
            <stop offset="1" stopColor="#D187E4" />
          </linearGradient>
          <linearGradient id="paint1_linear_100_1029" x1="21.2453" y1="13.095" x2="6.28431" y2="37.5073"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#F5F58B" />
            <stop offset="0.638352" stopColor="#FDC88E" />
            <stop offset="1" stopColor="#E583CD" />
          </linearGradient>
          <linearGradient id="paint2_linear_100_1029" x1="27.136" y1="0.0126338" x2="12.7628" y2="16.2839"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#EDFB96" />
            <stop offset="0.494792" stopColor="#B5E0F1" />
            <stop offset="1" stopColor="#B4ABF2" />
          </linearGradient>
        </defs>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <Link href="/">{logo}</Link>;
});

export default Logo;
