// eslint-disable-next-line simple-import-sort/imports
import { AppBar, Box, Container, Toolbar } from '@mui/material'
// @mui
import { alpha, styled, useTheme } from '@mui/material/styles'
import { useLocation } from 'react-router-dom'

// import { CustomConnectButton } from 'src/components/connection-button';
import logo from '../../../assets/logo/logo_full.svg'
// components
import Image from '../../components/Image'
// config
import { HEADER } from '../../config'
// import { CustomConnectButton } from '../../../components/connection-button'
// rainbowkit
// hooks
import useOffSetTop from '../../hooks/useOffSetTop'
import useResponsive from '../../hooks/useResponsive'
// utils
import cssStyles from '../../utils/cssStyles'
import navConfig from './MenuConfig'
//
import MenuDesktop from './MenuDesktop'
import MenuMobile from './MenuMobile'
// ----------------------------------------------------------------------

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: HEADER.MOBILE_HEIGHT,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('md')]: {
    height: HEADER.MAIN_DESKTOP_HEIGHT,
  },
}))

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
  boxShadow: `0 8px 16px 0 ${alpha('#000000', 0.16)}`,
}))

// ----------------------------------------------------------------------

export default function MainHeader() {
  const isOffset = useOffSetTop(HEADER.MAIN_DESKTOP_HEIGHT)

  const theme = useTheme()

  const location = useLocation()

  const isDesktop = useResponsive('up', 'md')

  const isHome = location.pathname === '/'

  return (
    <AppBar sx={{ boxShadow: 0, bgcolor: 'transparent' }}>
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            ...cssStyles(theme).bgBlur(),
            height: { md: HEADER.MAIN_DESKTOP_HEIGHT - 16 },
          }),
        }}
      >
        <Container
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <a href="https://agilely.io/">
            <Image src={logo} sx={{ mx: { xs: 'auto', md: 'inherit' }, width: '140px' }} />
          </a>

          <Box sx={{ flexGrow: 1 }} />

          {isDesktop && <MenuDesktop isOffset={isOffset} isHome={isHome} navConfig={navConfig} />}

          {/* <CustomConnectButton /> */}

          {!isDesktop && <MenuMobile isOffset={isOffset} isHome={isHome} navConfig={navConfig} />}
        </Container>
      </ToolbarStyle>

      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
  )
}
