export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  bridge: '/bridge',
  components: '/components',
  pool: '/#/pool',
  swap: '/#/swap',
  mint: '/mint',
  doucmentation: 'https://docs.agilely.io/'
}
