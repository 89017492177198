// @mui
import { Box, Container, Grid, Link, Stack, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import logo from '../../../assets/logo/logo_full.svg'
// components
import Image from '../../components/Image'
import SocialsButton, { defaultSocialLinks } from '../../components/SocialsButton'
// routes
import { PATH_PAGE } from '../../routes/paths'
// ----------------------------------------------------------------------
const SOCIAL_COLOR = 'rgb(185, 219, 247)'

const LINKS = [
  {
    headline: 'Community',
    children: [
      { name: 'Discord', href: defaultSocialLinks.discord },
      { name: 'Twitter', href: defaultSocialLinks.twitter },
      { name: 'Telegram', href: defaultSocialLinks.telegram },
    ],
  },
  {
    headline: 'Protocol',
    children: [
      { name: 'Docs', href: PATH_PAGE.comingSoon },
      { name: 'Blog', href: PATH_PAGE.comingSoon },
      { name: 'Github', href: PATH_PAGE.comingSoon },
    ],
  },
  {
    headline: 'Contact us',
    children: [{ name: 'agilely.io@hotmail.com', href: 'mailto:agilely.io@hotmail.com' }],
  },
]

// ----------------------------------------------------------------------

export default function MainFooter() {
  const theme = useTheme()
  const hoverColor = 'rgb(33, 38, 45)'
  return (
    <Box sx={{ position: 'relative', bottom: 0 }}>
      <Container sx={{ pt: 10 }}>
        <Grid
          container
          justifyContent={{ xs: 'center', md: 'space-between' }}
          sx={{ textAlign: { xs: 'center', md: 'left' } }}
        >
          <Grid item xs={8} md={3}>
            <a href="https://agilely.io/">
              <Image src={logo} sx={{ mx: { xs: 'auto', md: 'inherit' }, width: '140px' }} />
            </a>
            <Stack
              direction="row"
              justifyContent={{ xs: 'center', md: 'flex-start' }}
              sx={{ mt: 1, mb: { xs: 5, md: 0 } }}
            >
              <SocialsButton sx={{ mx: 0.5, color: SOCIAL_COLOR, position: 'relative', zIndex: 1 }} />
            </Stack>
          </Grid>

          <Grid item xs={12} md={7}>
            <Stack sx={{ pt: 2 }} spacing={5} direction={{ xs: 'column', md: 'row' }} justifyContent="space-between">
              {LINKS.map((list) => (
                <Stack key={list.headline} spacing={2}>
                  <Typography component="p" variant="overline" sx={{ fontWeight: 'bold' }}>
                    {list.headline}
                  </Typography>
                  {list.children.map((link) => (
                    <Link
                      key={link.name}
                      href={link.href || '#'}
                      target="_blank"
                      color="inherit"
                      variant="body2"
                      underline="none"
                      sx={{
                        display: 'block',
                        '&:hover': {
                          textDecoration: 'underline',
                        },
                      }}
                    >
                      {link.name}
                    </Link>
                  ))}
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>

        <Typography
          component="p"
          variant="body2"
          sx={{
            mt: 10,
            pb: 5,
            fontSize: 13,
            textAlign: { xs: 'center', md: 'center' },
          }}
        >
          © 2023. All rights reserved
        </Typography>
      </Container>
    </Box>
  )
}
