// routes
// components
import Iconify from '../../components/Iconify'
import { PATH_PAGE } from '../../routes/paths'

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22,
}

const BASE_URL = 'https://agilely.io'

const menuConfig = [
  {
    title: 'Home',
    icon: <Iconify icon="eva:home-fill" {...ICON_SIZE} />,
    path: BASE_URL + '/',
  },
  {
    title: 'Mint',
    icon: <Iconify icon="ic:round-grain" {...ICON_SIZE} />,
    path: BASE_URL + PATH_PAGE.mint,
  },
  {
    title: 'Swap',
    icon: <Iconify icon="ic:round-grain" {...ICON_SIZE} />,
    path: PATH_PAGE.swap,
  },
  {
    title: 'Pools',
    icon: <Iconify icon="ic:round-grain" {...ICON_SIZE} />,
    path: PATH_PAGE.pool,
  },
  {
    title: 'Bridge',
    icon: <Iconify icon="ic:round-grain" {...ICON_SIZE} />,
    path: BASE_URL + PATH_PAGE.bridge,
  },
  {
    title: 'Dashboard',
    icon: <Iconify icon="ic:round-grain" {...ICON_SIZE} />,
    path: BASE_URL + PATH_PAGE.comingSoon,
  },
  {
    title: 'Earn',
    icon: <Iconify icon="ic:round-grain" {...ICON_SIZE} />,
    path: BASE_URL + PATH_PAGE.comingSoon,
  },
  {
    title: 'DAO',
    icon: <Iconify icon="ic:round-grain" {...ICON_SIZE} />,
    path: BASE_URL + PATH_PAGE.comingSoon,
  },
]

export default menuConfig
